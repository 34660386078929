.littleImage {
  visibility: visible;
}

.bigImage {
  visibility: visible;
}

.spacer {
  height: 30vw;
}

@media (min-width: 750px) {
  .littleImage {
    visibility: hidden;
  }
}

@media (max-width: 750px) {
  .spacer {
    height: 50vw;
  }

  .bigImage {
    visibility: hidden;
  }
}
